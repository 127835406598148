import React from 'react';
import ReactDOM from 'react-dom';
import Form from '../src/components/Form';

/**
 * @config prepareing config prop.
 * api: url for the server endpoint
 * title: form title
 * successMessage: message will show in the UI when mail is successfully sent.
 * errorMessage: message will show in the UI when mail is not sent.
 * fields: this is the name of each field. This should be exact order of the fieldsConfig and fieldsConfig.fieldName should be  the same
 * fieldsConfig = settings for each input/textarea field
 */
const config = {
  api: `${process.env.REACT_APP_API}`,
  title: 'What would you like to see?',
  successMessage: 'Thanks. Check back soon. You should see this added within 24 hours.',
  errorMessage: 'Sorry, something went wrong. Please try requesting again later.',
  fields:{
    firstName: '',
    lastName: '',
    email: '',
    msg: ''
  },
  fieldsConfig:  [
   { id: 4, label: '', fieldName: 'msg', type: 'textarea',placeholder:'Enter request here...', isRequired: true , klassName:'message-field'}
  ]
}
ReactDOM.render(<Form config={config} />, document.getElementById('root'));
